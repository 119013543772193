import React from "react"
import { Container } from "components/library"
import { graphql } from "gatsby"
import { transformJobs } from "utils"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { NotionArticle } from "utils/notion"
import { Listing } from "components/pages/careers/listing/listing"

type P = {
	pageContext: {
		jobs: NotionArticle[]
	}
}

const Careers = ({ pageContext: { jobs } }: P) => {
	const { t } = useTranslation()
	const transformedJobs = transformJobs(jobs)

	return (
		<main>
			<Container className="flex flex-col gap-16 sm:gap-28 py-16 sm:py-28">
				<div className="flex flex-col gap-6">
					<span className="text-5xl md:text-6xl lg:text-[68px]">
						{t("careers.hero.heading")}
					</span>
					<p className="text-lg sm:text-2xl text-[#505F79]">
						{t("careers.hero.desc")}
					</p>
				</div>

				<Listing jobs={transformedJobs} />
			</Container>
		</main>
	)
}

export default Careers

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
